.forgotPasswordMainWrap {
  background-color: #202027;
  box-shadow: 0px 2px 4px 0px #ffffff05 inset;
  padding: 16px;
  /* position: relative; */
  min-width: 440px;
  /* min-height: 100vh; */
  height: 100vh;
  /* overflow: auto; */
}
.forgotPasswordMainWrap::before {
  background-image: url(../../../../../public/images/loginTopShadow.png);
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  left: 46px;
  background-position: top;
}
.loginHeadeWrap .closeIcon {
  background: var(--Black-500, #2b2b38);
  border: 1.4px solid var(--stroke-4, #ffffff0a);
  box-shadow: 0px 2px 12px 0px #ffffff05 inset;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  position: relative;
  z-index: 9999;
}
.forgotPasswordMainWrap .loginHeadeWrap {
  display: flex;
  justify-content: end;
}
.forgotPasswordMainWrap .loginLogoWrap {
  margin-top: 8px;
  text-align: center;
}
.forgotPasswordMainWrap .loginLogoWrap h1 {
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  margin: 0px;
}

.enterMailMainWrap p {
  font-family: Proxima Nova;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  color: #757786;
  margin-top: 8px;
  margin-bottom: 0px;
}

.enterMailMainWrap .logInBtnWrap {
  margin-top: 16px;
}
.enterMailMainWrap .logInBtnWrap button {
  background: radial-gradient(
    201.67% 201.67% at 50% -31.67%,
    #5d69cf 0%,
    #342a6f 100%
  );
  border: 1.4px solid var(--Stroke-primary-btn, #6a71c7d6);
  box-shadow: 0px 4px 12px 2px #6c5dc13d;
  padding: 10.5px;
  width: 100%;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  color: #ffffff;
  text-transform: math-auto;
}
.enterMailMainWrap .backBtnFull button {
  background: var(--Black-500, #2b2b38);
  border: 1.4px solid var(--stroke-4, #ffffff0a);
  box-shadow: 0px 2px 12px 0px #ffffff05 inset;
}

.enterMailMainWrap .backBtnFull {
  margin-top: 8px !important;
}

.forgotPasswordInput {
  margin-top: 16px;
}
.loginFormMainWrap .resendBottomTxt {
  margin-top: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #757786;
  margin-bottom: 0px;
}
.masterMainWrap {
  /* position: relative; */
  max-width: 440px;
  margin: 0 auto;
  width: 100%;
}
.loginFormMainWrap .resendBottomTxt .blueColor {
  color: #00daea;
}
.marginTop12 {
  margin-top: 12px;
}
.masterMainWrap .newLoginInput label {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #757786;
  margin-bottom: 4px;
}

/* otp css */
.otpMainWrap .input-fields-container {
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.otpMainWrap .input-box {
  width: 28px;
  height: 32px;
  background: var(--Black-800, #1d1d23);
  color: #ddd;
  border: none;
  outline: none;
  text-align: center;
  font-size: 20px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  box-shadow: 0px 2px 8px 0px #0000001f inset;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #ffffff;
}

.otpMainWrap .input-box::placeholder {
  color: #4d4d5b;
  transform: translateY(6px);
  display: inline-block;
}

.otpMainWrap .input-box:focus {
  background: var(--Black-800, #1d1d23);
}
.enterMailMainWrap .bottomBtnFixedWrap {
  position: fixed;
  bottom: 47px;
  min-width: 408px;
}
.errormsgWrap .form-error-message-submit {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ea565b;
  margin: 4px 0 0 0;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .masterMainWrap {
    /* height: 100vh; */
    /* position: relative; */
  }
  .enterMailMainWrap .bottomBtnFixedWrap {
    position: absolute;
    width: 90%;
    bottom: 16px;
    z-index: 99999 !important;
    min-width: 0px;
  }
  .forgotPasswordMainWrap {
    min-width: 0px;
  }
  .forgotPasswordMainWrap::before {
    left: 0;
  }
  /* .forgotPasswordMainWrap{
    min-height: 85vh;
  } */
}

.form-error-message-submit {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ea565b !important;
  margin: 4px 0 0 0;
}
