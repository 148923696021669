.circular-progress-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.tick {
  position: absolute;
  font-size: 1.5rem;
  margin-top: 10px;
}
