.earnSvgIcon,
.pagination,
.referral-input,
.referral-page,
.referral-table,
.referral-view .wrapper-box {
  position: relative;
}
.referaltheadMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column-new
  .clipPath,
.referaltheadMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column-new
  img {
  clip-path: polygon(
    40% 7.67949%,
    43.1596% 6.20615%,
    46.52704% 5.30384%,
    50% 5%,
    53.47296% 5.30384%,
    56.8404% 6.20615%,
    60% 7.67949%,
    81.65064% 20.17949%,
    84.50639% 22.17911%,
    86.97152% 24.64425%,
    88.97114% 27.5%,
    90.44449% 30.6596%,
    91.34679% 34.02704%,
    91.65064% 37.5%,
    91.65064% 62.5%,
    91.34679% 65.97296%,
    90.44449% 69.3404%,
    88.97114% 72.5%,
    86.97152% 75.35575%,
    84.50639% 77.82089%,
    81.65064% 79.82051%,
    60% 92.32051%,
    56.8404% 93.79385%,
    53.47296% 94.69616%,
    50% 95%,
    46.52704% 94.69616%,
    43.1596% 93.79385%,
    40% 92.32051%,
    18.34936% 79.82051%,
    15.49361% 77.82089%,
    13.02848% 75.35575%,
    11.02886% 72.5%,
    9.55551% 69.3404%,
    8.65321% 65.97296%,
    8.34936% 62.5%,
    8.34936% 37.5%,
    8.65321% 34.02704%,
    9.55551% 30.6596%,
    11.02886% 27.5%,
    13.02848% 24.64425%,
    15.49361% 22.17911%,
    18.34936% 20.17949%
  );
}
.referral-view .wrapper-box:before {
  content: "";
  background: url(../../images/referral-bg.jpg) top center/contain no-repeat;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 50px;
  height: 602px;
}
.referral-page {
  margin: 50px 0;
}
.referral-head-title h2 {
  color: #fff;
  font-family: Jost;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 50px;
  text-transform: uppercase;
  margin: 0;
}
.referral-head {
  padding-top: 120px;
}
.referral-head-title {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.referral-head-title p {
  color: #757786;
  text-align: center;
  font-family: Jost;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  margin: 0;
}
.referral-head-list {
  padding-top: 60px;
}
.referral-head-list .fw-500,
.referral-head-list .fw-600 {
  font-weight: 600 !important;
}
.referral-head-list ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.referral-head-list ul li {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: 24px;
}
.referral-content {
  margin-top: 30px;
  border-radius: 20px;
  border-top: 2px solid rgba(255, 255, 255, 0.04);
  background: #24242b;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.08),
    0 2px 4px 0 rgba(255, 255, 255, 0.02) inset;
  overflow: hidden;
}
.referral-input-grid {
  padding: 20px 20px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}
.referral-input-box {
  width: calc(50% - 10px);
  flex-direction: column;
  gap: 10px;
  display: flex;
}
.referral-input-box label {
  color: #757786;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  text-transform: capitalize;
}
.pagination li a,
.referral-input button,
.referral-input input,
.referral-progress-container .progress-value p,
.referralGreen,
.referralProgress {
  font-style: normal;
  line-height: normal;
  font-weight: 500;
}
.referral-input {
  display: flex;
  flex-wrap: wrap;
}
.referral-input input {
  border-radius: 10px;
  background: #1d1d23;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12) inset;
  border: none;
  padding: 15px 115px 15px 15px;
  height: 50px;
  width: 100%;
  color: #fff;
  font-family: Jost;
  font-size: 16px;
}
.referral-input input::placeholder {
  color: #fff;
}
.referral-input button {
  border-radius: 10px;
  border: 1.4px solid rgba(106, 113, 199, 0.84);
  background: radial-gradient(
    201.67% 201.67% at 50% -31.67%,
    #5d69cf 0,
    #342a6f 100%
  );
  box-shadow: 0 4px 12px 2px rgba(108, 93, 193, 0.24);
  color: #fff;
  font-size: 16px;
  text-transform: capitalize;
  width: 100px;
  position: absolute;
  right: 0;
  height: 100%;
}
.backBtnDiv,
.referral-input span {
  display: none;
}
.referral-table-content {
  border-radius: 12px;
  background: #1d1d23;
  width: 100%;
  overflow-x: scroll;
  position: relative;
}
.referral-table-content::-webkit-scrollbar-track {
  border-radius: 12px;
  background: #1d1d23;
  width: 2px;
  height: 2px;
}
.referral-table-content::-webkit-scrollbar {
  border-radius: 8px;
  border: 1.4px solid rgba(255, 255, 255, 0.04);
  background: unset;
  box-shadow: 0 2px 12px 0 rgba(255, 255, 255, 0.02) inset;
  width: 2px;
  height: 2px;
  display: block;
}
.referral-table-content::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background: #2b2b38;
  width: 2px;
  height: 2px;
}
.referral-table-content table thead tr th {
  color: #757786;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding: 18px 40px;
  white-space: nowrap;
  text-align: left;
}
.referral-table-content table tbody tr td,
.userName p {
  line-height: 40px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.referral-table-content table tbody tr,
.referral-table-content table thead tr {
  border-bottom: 1px solid #333246;
}
.referral-table-content table tbody tr td {
  color: #757786;
  padding: 12px 40px;
  vertical-align: middle;
  white-space: nowrap;
}
.referral-table-content table thead tr th:first-child,
.referral-table-content table tr td:first-child {
  padding-right: 0;
}
.referral-table-content table thead tr th:nth-child(3) {
  text-align: center;
}
.referral-table-content table tbody tr td:nth-child(3),
.referral-table-content table tbody tr td:nth-child(3) div {
  text-align: center;
  margin: 0 auto;
}
.referral-table-content table {
  width: 100%;
  overflow-y: auto;
  border-spacing: 0;
}
.referral-table-content table tbody tr:nth-of-type(odd) {
  background: rgba(53, 53, 66, 0.24);
}
.userName {
  display: flex;
  align-items: center;
  gap: 12px;
}
.wallet-referral-detail {
  width: 100%;
  overflow-y: hidden;
}
.userName img {
  border: 1px solid;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  clip-path: polygon(
    40% 7.67949%,
    43.1596% 6.20615%,
    46.527% 5.30384%,
    50% 5%,
    53.473% 5.30384%,
    56.8404% 6.20615%,
    60% 7.67949%,
    81.6506% 20.1795%,
    84.5064% 22.1791%,
    86.9715% 24.6442%,
    88.9711% 27.5%,
    90.4445% 30.6596%,
    91.3468% 34.027%,
    91.6506% 37.5%,
    91.6506% 62.5%,
    91.3468% 65.973%,
    90.4445% 69.3404%,
    88.9711% 72.5%,
    86.9715% 75.3558%,
    84.5064% 77.8209%,
    81.6506% 79.8205%,
    60% 92.3205%,
    56.8404% 93.7939%,
    53.473% 94.6962%,
    50% 95%,
    46.527% 94.6962%,
    43.1596% 93.7939%,
    40% 92.3205%,
    18.3494% 79.8205%,
    15.4936% 77.8209%,
    13.0285% 75.3558%,
    11.0289% 72.5%,
    9.55551% 69.3404%,
    8.65321% 65.973%,
    8.34936% 62.5%,
    8.34936% 37.5%,
    8.65321% 34.027%,
    9.55551% 30.6596%,
    11.0289% 27.5%,
    13.0285% 24.6442%,
    15.4936% 22.1791%,
    18.3494% 20.1795%
  );
}
.userName p {
  color: #fff;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
  width: 82px;
  margin: 0;
}
.referaltheadMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column-new
  .profileSpan,
.referral-progress-container {
  display: flex;
  align-items: center;
}
.referral-progress-container .progress {
  background: var(--Black-800, #1d1d23);
  height: 5px;
  width: 120px;
  border-radius: 4px;
}
.referral-progress-container .progress-bar {
  border-radius: 20px;
  height: 100%;
  background: radial-gradient(
    201.67% 201.67% at 50% -31.67%,
    #5d69cf 0,
    #342a6f 100%
  );
  box-shadow: 0 2px 12px 2px rgba(108, 93, 193, 0.24);
}
.referral-progress-container .progress-value p {
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.48px;
  margin: 0;
}
.referral-table-content table tbody tr td.date-value {
  color: #fff;
}
.referral-progress-container .progress-value p span {
  color: #757786;
}
.show-less {
  margin-top: 20px;
}
.wallet-referral-btn svg {
  transition: transform 0.3s ease-in-out;
}
.show-less svg {
  transform: rotate(180deg);
}
.referralGreen {
  color: #6cd9a5 !important;
  font-size: 14px;
  text-align: left;
}
.referaltheadMainWrap .transaction-body .transaction-body-column-new .profileBG,
.referralBg {
  display: flex;
  color: #fff !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 82px;
}
.referralBg {
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  border-radius: 6px;
  border: 2px solid #6cd9a5d6;
  background: radial-gradient(
    216.25% 216.25% at 50% -86.25%,
    #6cd9a5 0,
    #3aa16f 100%
  );
  height: 30px;
}
.referralProgress {
  background: #2b2b38;
  color: #666773;
  font-family: Jost;
  font-size: 14px;
  height: 40px;
  width: 85px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  border: 1px solid;
}
.refferal-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.disabled-page {
  color: #808e9b;
}
.pagination {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style-type: none;
}
.pagination li .previous-page {
  margin-right: 20px;
}
.pagination li .next-page {
  margin-left: 20px;
}
.pagination li a {
  color: #fff;
  font-size: 16px;
  border: 1px solid transparent;
  background: 0 0;
  height: 40px !important;
  width: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.pagination-active a {
  background: #202026;
  border-width: 1px;
  border-color: #6a71c7 !important;
  border-style: solid;
  box-shadow: 2px 2px 20px 0 #3c3c43;
}
.pagination li,
.referral-input button,
.referralBg {
  cursor: pointer;
}
li.next,
li.previous {
  padding: 0;
}
.referaalTableMainWrap,
li a.next-page,
li a.previous-page {
  display: flex;
}
/* .earnSvgIcon {
  width: 36px;
  height: 40px;
  display: flex;
  align-items: center;
} */
.earnSvgIcon svg {
  top: 56%;
  left: 52%;
  width: 36px;
  height: 40px;
  position: absolute;
  transform: translate(-50%, -50%);
}
/* .headerSvgIcon,
.headerSvgIcon svg,
.headerSvgIconCoin {
  width: 37px !important;
  height: 37px !important;
} */
.headerSvgIcon img {
  height: 24px !important;
  width: 24px !important;
}
.bonusCoinSvg,
.bonusCoinSvg svg {
  width: 43px;
  height: 43px;
}
.bonusCoinSvg img {
  position: absolute !important;
}
.bonusCoinSvg svg {
  left: 36%;
}
.referaltheadMainWrap .transaction-head {
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
.referaltheadMainWrap .transaction-head .fGap {
  display: flex;
  align-items: center;
  height: 50px;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;
}
.referaltheadMainWrap .transaction-head .sGap {
  display: flex;
  align-items: center;
  height: 50px;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 169.33px;
}
.referaltheadMainWrap .transaction-body .fGap .transaction-body-column,
.referaltheadMainWrap .transaction-head .fGap .transaction-head-column {
  margin-right: 20px;
  min-width: 36px;
}
.referaltheadMainWrap .transaction-head .transaction-head-column {
  padding: 13px 10px 13px 8px;
}
.referaltheadMainWrap .transaction-head .transaction-head-column-new {
  padding: 12px 10px 12px 8px;
}
.referaltheadMainWrap
  .transaction-body
  .transaction-body-column-new:first-child,
.referaltheadMainWrap
  .transaction-head
  .transaction-head-column-new:first-child {
  min-width: 147px;
}
.referaltheadMainWrap
  .transaction-body
  .transaction-body-column-new:nth-child(2),
.referaltheadMainWrap
  .transaction-head
  .transaction-head-column-new:nth-child(2) {
  min-width: 102px;
}
.referaltheadMainWrap
  .transaction-body
  .transaction-body-column-new:nth-child(3),
.referaltheadMainWrap
  .transaction-head
  .transaction-head-column-new:nth-child(3) {
  min-width: 243px;
}
.referaltheadMainWrap
  .transaction-body
  .transaction-body-column-new:nth-child(4),
.referaltheadMainWrap
  .transaction-head
  .transaction-head-column-new:nth-child(4) {
  min-width: 104px;
}
.referaltheadMainWrap .transaction-head span {
  font-size: 14px;
  font-weight: 500;
  color: #757786;
  line-height: 20px;
}
.referaltheadMainWrap .transaction-body .transaction-body-row {
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
.referaltheadMainWrap .transaction-body .transaction-body-row .fGap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;
}
.referaltheadMainWrap .transaction-body .transaction-body-row .sGap {
  display: flex;
  align-items: center;
  height: 50px;
  gap: 169.33px;
}
.referaltheadMainWrap .transaction-body span {
  font-size: 14px;
  font-weight: 500;
  color: #757786;
  line-height: 20px;
  display: flex;
}
.referaltheadMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column {
  padding: 10px 8px;
}
.referaltheadMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column-new {
  padding: 0 8px;
}
.referaltheadMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column-new
  .profileSpan
  span {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  line-height: 20px;
}
.referaltheadMainWrap .transaction-body:nth-child(2n) {
  background-color: #282831;
}
.referaltheadMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column-new
  img {
  height: 30px;
  width: 30px;
  display: flex;
}
.referaltheadMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column-new
  .clipPath {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 35px;
  background: #3e3e44;
  max-height: 35px;
  object-fit: cover;
  align-items: center;
  aspect-ratio: 1;
  border-color: #3e3e44;
  border-radius: 4px;
  justify-content: center;
}
.userProfileLevel {
  display: none !important;
}
.referaltheadMainWrap
  .transaction-body
  .transaction-body-column-new
  .profileBG {
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  border-radius: 6px;
  border: 1px solid var(--stroke-4, #ffffff0a);
  background: var(--Black-500, #2b2b38);
  height: 30px;
}
@media screen and (max-width: 991px) {
  .referral-table-content {
    border-radius: 0;
  }
  .referral-page {
    margin: 50px 0;
  }
  .referral-head {
    padding-top: 12px;
  }
  .referral-head-title h2 {
    font-size: 18px;
    line-height: 24px;
  }
  .pagination,
  .referral-head-title {
    gap: 8px;
  }
  .referral-head-title p {
    font-size: 14px;
    line-height: 16px;
  }
  .referral-head-list {
    padding-top: 24px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .referral-view .wrapper-box:before {
    top: 50px;
  }
  .referral-head-list ul {
    justify-content: space-between;
  }
  .referral-head-list ul li img {
    order: 1;
    width: 28px;
  }
  .earnSvgIcon {
    height: 28px;
    width: 28px;
  }
  .referral-head-list ul li b.ref-earned {
    order: 1;
  }
  .referral-head-list ul li span {
    order: 3;
  }
  .referral-input-grid {
    padding: 12px 12px 15px;
    gap: 17px;
    flex-direction: column;
  }
  .referral-input-box {
    width: 100%;
    gap: 4px;
  }
  .referral-input-box label {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }
  .referral-input input {
    border-radius: 8px;
    height: 32px;
    font-size: 14px;
    line-height: 16px;
    padding: 8px 12px;
    font-weight: 400;
  }
  .referral-progress-container .progress-value p,
  .referralBg,
  .referralGreen,
  .userName p {
    font-weight: 500;
    font-size: 12px;
  }
  .referral-input button,
  .userName img {
    display: none;
  }
  .referral-input span {
    display: flex;
    position: absolute;
    right: 3px;
    width: 28px;
    height: 28px;
  }
  .referral-input span img {
    position: absolute;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
  }
  .referral-table-content table tbody tr td {
    font-size: 12px;
    line-height: 16px;
    padding: 8px 12px;
    font-weight: 500;
    white-space: unset;
  }
  .referral-table-content table thead tr th {
    font-size: 12px;
    line-height: 16px;
    padding: 12px;
  }
  .userName p {
    line-height: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 52px;
  }
  .referral-table-content table thead tr th:nth-child(3) {
    text-align: left;
  }
  .referralBg {
    height: 26px;
    width: 64px;
    border-radius: 6px;
    border: 2px solid #6cd9a5d6;
    background: radial-gradient(
      216.25% 216.25% at 50% -86.25%,
      #6cd9a5 0,
      #3aa16f 100%
    );
  }
  .referralProgress {
    font-size: 12px;
    height: 26px;
    width: 64px;
    border-radius: 6px;
  }
  .referral-progress-container {
    gap: 4px;
  }
  .referral-progress-container .progress {
    height: 2px;
    width: 80px;
  }
  .refferal-pagination {
    padding: 15px 13px;
  }
  .order_arrange {
    order: 1 !important;
  }
  .referral-head-list ul li {
    font-size: 14px;
    gap: 6px !important;
    line-height: 16px;
    border-radius: 6px;
  }
}
@media screen and (max-width: 767px) {
  .referral-table-content table tbody tr td {
    color: #fff;
  }
  .headerSvgIcon,
  .headerSvgIconCoin {
    width: 36px !important;
    height: 36px !important;
  }
  .headerSvgIconCoin {
    left: 6px !important;
  }
  .headerSvgIcon svg {
    width: 31.5px !important;
    height: 31.5px !important;
  }
  .pagination li a {
    border-radius: 8px;
    padding: 0;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pagination .previous {
    margin-right: 14px !important;
  }
  .pagination .next {
    margin-left: 14px !important;
  }
  .pagination .pagination-active {
    box-shadow: 0 4px 12px 2px #6c5dc13d;
  }
  .pagination,
  .referaltheadMainWrap .transaction-body .transaction-body-row .sGap {
    gap: 0;
  }
  .refferal-pagination {
    padding: 14px 12px 12px;
  }
  .referral-content {
    margin-top: 16px;
    border-radius: 16px;
  }
  .referaalTableMainWrap {
    overflow-x: auto;
    overflow-y: hidden;
  }
  .referral-view .wrapper-box:before {
    background-image: url("../../../public/images/history-page-bg.jpg") !important;
  }
  .referaltheadMainWrap .transaction-head {
    padding-left: 8px;
    padding-right: 8px;
    height: 40px;
  }
  .referaltheadMainWrap .transaction-head .sGap {
    gap: 0 !important;
  }
  .referaltheadMainWrap
    .transaction-body
    .transaction-body-column-new:first-child,
  .referaltheadMainWrap
    .transaction-head
    .transaction-head-column-new:first-child {
    min-width: 131px;
  }
  .referaltheadMainWrap
    .transaction-body
    .transaction-body-column-new:nth-child(2),
  .referaltheadMainWrap
    .transaction-head
    .transaction-head-column-new:nth-child(2) {
    min-width: 86px;
  }
  .referaltheadMainWrap
    .transaction-body
    .transaction-body-column-new:nth-child(3),
  .referaltheadMainWrap
    .transaction-head
    .transaction-head-column-new:nth-child(3) {
    min-width: 104px;
  }
  .referaltheadMainWrap
    .transaction-body
    .transaction-body-column-new:nth-child(4),
  .referaltheadMainWrap
    .transaction-head
    .transaction-head-column-new:nth-child(4) {
    min-width: 88px;
  }
  .referaltheadMainWrap .transaction-body .fGap .transaction-body-column,
  .referaltheadMainWrap .transaction-head .fGap .transaction-head-column {
    margin-right: 0;
    min-width: 30px;
  }
  .referaltheadMainWrap .transaction-body .transaction-body-row {
    padding-left: 8px;
    padding-right: 8px;
    height: 48px;
  }
  .referaltheadMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column-new
    .profileSpan
    span,
  .referaltheadMainWrap .transaction-body span,
  .referaltheadMainWrap .transaction-head span {
    font-size: 12px;
    line-height: 16px;
  }
  .referaltheadMainWrap .transaction-body span {
    flex-wrap: wrap;
  }
  .mobileMargin0 {
    margin-left: 0 !important;
    margin-top: 4px !important;
  }
  .referaltheadMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column-new
    .profileSpan
    span {
    margin-left: 8px;
  }
  .referaltheadMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column-new
    img {
    height: 32px;
    width: 32px;
  }
  .referaltheadMainWrap .transaction-head .transaction-head-column-new {
    padding: 12px 8px;
  }
  .referral-page {
    margin-bottom: 24px;
  }
  .referalBackBtnDiv {
    display: block;
    padding-top: 18px;
    padding-left: 8px;
  }
  .earnSvgIcon svg {
    height: 28px;
    width: 28px;
    left: 48%;
  }
  .userProfileLevel {
    left: 50%;
    bottom: 0;
    height: 12px;
    padding: 0 2px;
    font-size: 10px !important;
    max-width: 24px;
    min-width: 24px;
    transform: translateX(-50%);
    font-weight: 500 !important;
    line-height: 12px !important;
    border-radius: 4px !important;
    color: #fff;
    border: 1px solid #383e4e;
    position: absolute;
    background: #383e4e;
    box-shadow: inset 0 4px 4px rgba(255, 255, 255, 0.04);
    font-style: normal;
    text-align: center;
    margin-left: 0 !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  .positionrelative {
    position: relative;
  }
  .referralBg {
    width: 70px !important;
    height: 28px !important;
    font-size: 12px !important;
  }
  .referaltheadMainWrap
    .transaction-body
    .transaction-body-column-new
    .profileBG {
    width: 70px !important;
    height: 28px !important;
    font-size: 12px !important;
    color: #757786 !important;
  }
  .pagination li a,
  .refferal-pagination .pagination .previous .previous-page svg {
    height: 28px !important;
    width: 28px !important;
  }
}

.earnSvgIcon,
.pagination,
.referral-input,
.referral-page,
.referral-table,
.referral-view .wrapper-box {
  position: relative;
}
.referaltheadMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column-new
  .clipPath,
.referaltheadMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column-new
  img {
  clip-path: polygon(
    40% 7.67949%,
    43.1596% 6.20615%,
    46.52704% 5.30384%,
    50% 5%,
    53.47296% 5.30384%,
    56.8404% 6.20615%,
    60% 7.67949%,
    81.65064% 20.17949%,
    84.50639% 22.17911%,
    86.97152% 24.64425%,
    88.97114% 27.5%,
    90.44449% 30.6596%,
    91.34679% 34.02704%,
    91.65064% 37.5%,
    91.65064% 62.5%,
    91.34679% 65.97296%,
    90.44449% 69.3404%,
    88.97114% 72.5%,
    86.97152% 75.35575%,
    84.50639% 77.82089%,
    81.65064% 79.82051%,
    60% 92.32051%,
    56.8404% 93.79385%,
    53.47296% 94.69616%,
    50% 95%,
    46.52704% 94.69616%,
    43.1596% 93.79385%,
    40% 92.32051%,
    18.34936% 79.82051%,
    15.49361% 77.82089%,
    13.02848% 75.35575%,
    11.02886% 72.5%,
    9.55551% 69.3404%,
    8.65321% 65.97296%,
    8.34936% 62.5%,
    8.34936% 37.5%,
    8.65321% 34.02704%,
    9.55551% 30.6596%,
    11.02886% 27.5%,
    13.02848% 24.64425%,
    15.49361% 22.17911%,
    18.34936% 20.17949%
  );
}
.referral-view .wrapper-box:before {
  content: "";
  background: url(../../images/referral-bg.jpg) top center/contain no-repeat;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 50px;
  height: 602px;
}
.referral-page {
  margin: 50px 0;
}
.referral-head-title h2 {
  color: #fff;
  font-family: Jost;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 50px;
  text-transform: uppercase;
  margin: 0;
}
.referral-head {
  padding-top: 120px;
}
.referral-head-title {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.referral-head-title p {
  color: #757786;
  text-align: center;
  font-family: Jost;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  margin: 0;
}
.referral-head-list {
  padding-top: 60px;
}
.referral-head-list .fw-500,
.referral-head-list .fw-600 {
  font-weight: 600 !important;
}
.referral-head-list ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.referral-head-list ul li {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: 24px;
}
.referral-content {
  margin-top: 30px;
  border-radius: 20px;
  border-top: 2px solid rgba(255, 255, 255, 0.04);
  background: #24242b;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.08),
    0 2px 4px 0 rgba(255, 255, 255, 0.02) inset;
  overflow: hidden;
}
.referral-input-grid {
  padding: 20px 20px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}
.referral-input-box {
  width: calc(50% - 10px);
  flex-direction: column;
  gap: 10px;
  display: flex;
}
.referral-input-box label {
  color: #757786;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  text-transform: capitalize;
}
.pagination li a,
.referral-input button,
.referral-input input,
.referral-progress-container .progress-value p,
.referralGreen,
.referralProgress {
  font-style: normal;
  line-height: normal;
  font-weight: 500;
}
.referral-input {
  display: flex;
  flex-wrap: wrap;
}
.referral-input input {
  border-radius: 10px;
  background: #1d1d23;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12) inset;
  border: none;
  padding: 15px 115px 15px 15px;
  height: 50px;
  width: 100%;
  color: #fff;
  font-family: Jost;
  font-size: 16px;
}
.referral-input input::placeholder {
  color: #fff;
}
.referral-input button {
  border-radius: 10px;
  border: 1.4px solid rgba(106, 113, 199, 0.84);
  background: radial-gradient(
    201.67% 201.67% at 50% -31.67%,
    #5d69cf 0,
    #342a6f 100%
  );
  box-shadow: 0 4px 12px 2px rgba(108, 93, 193, 0.24);
  color: #fff;
  font-size: 16px;
  text-transform: capitalize;
  width: 100px;
  position: absolute;
  right: 0;
  height: 100%;
}
.backBtnDiv,
.referral-input span {
  display: none;
}
.referral-table-content {
  border-radius: 12px;
  background: #1d1d23;
  width: 100%;
  overflow-x: scroll;
  position: relative;
}
.referral-table-content::-webkit-scrollbar-track {
  border-radius: 12px;
  background: #1d1d23;
  width: 2px;
  height: 2px;
}
.referral-table-content::-webkit-scrollbar {
  border-radius: 8px;
  border: 1.4px solid rgba(255, 255, 255, 0.04);
  background: unset;
  box-shadow: 0 2px 12px 0 rgba(255, 255, 255, 0.02) inset;
  width: 2px;
  height: 2px;
  display: block;
}
.referral-table-content::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background: #2b2b38;
  width: 2px;
  height: 2px;
}
.referral-table-content table thead tr th {
  color: #757786;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding: 18px 40px;
  white-space: nowrap;
  text-align: left;
}
.referral-table-content table tbody tr td,
.userName p {
  line-height: 40px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.referral-table-content table tbody tr,
.referral-table-content table thead tr {
  border-bottom: 1px solid #333246;
}
.referral-table-content table tbody tr td {
  color: #757786;
  padding: 12px 40px;
  vertical-align: middle;
  white-space: nowrap;
}
.referral-table-content table thead tr th:first-child,
.referral-table-content table tr td:first-child {
  padding-right: 0;
}
.referral-table-content table thead tr th:nth-child(3) {
  text-align: center;
}
.referral-table-content table tbody tr td:nth-child(3),
.referral-table-content table tbody tr td:nth-child(3) div {
  text-align: center;
  margin: 0 auto;
}
.referral-table-content table {
  width: 100%;
  overflow-y: auto;
  border-spacing: 0;
}
.referral-table-content table tbody tr:nth-of-type(odd) {
  background: rgba(53, 53, 66, 0.24);
}
.userName {
  display: flex;
  align-items: center;
  gap: 12px;
}
.wallet-referral-detail {
  width: 100%;
  overflow-y: hidden;
}
.userName img {
  border: 1px solid;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  clip-path: polygon(
    40% 7.67949%,
    43.1596% 6.20615%,
    46.527% 5.30384%,
    50% 5%,
    53.473% 5.30384%,
    56.8404% 6.20615%,
    60% 7.67949%,
    81.6506% 20.1795%,
    84.5064% 22.1791%,
    86.9715% 24.6442%,
    88.9711% 27.5%,
    90.4445% 30.6596%,
    91.3468% 34.027%,
    91.6506% 37.5%,
    91.6506% 62.5%,
    91.3468% 65.973%,
    90.4445% 69.3404%,
    88.9711% 72.5%,
    86.9715% 75.3558%,
    84.5064% 77.8209%,
    81.6506% 79.8205%,
    60% 92.3205%,
    56.8404% 93.7939%,
    53.473% 94.6962%,
    50% 95%,
    46.527% 94.6962%,
    43.1596% 93.7939%,
    40% 92.3205%,
    18.3494% 79.8205%,
    15.4936% 77.8209%,
    13.0285% 75.3558%,
    11.0289% 72.5%,
    9.55551% 69.3404%,
    8.65321% 65.973%,
    8.34936% 62.5%,
    8.34936% 37.5%,
    8.65321% 34.027%,
    9.55551% 30.6596%,
    11.0289% 27.5%,
    13.0285% 24.6442%,
    15.4936% 22.1791%,
    18.3494% 20.1795%
  );
}
.userName p {
  color: #fff;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
  width: 82px;
  margin: 0;
}
.referaltheadMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column-new
  .profileSpan,
.referral-progress-container {
  display: flex;
  align-items: center;
}
.referral-progress-container .progress {
  background: var(--Black-800, #1d1d23);
  height: 5px;
  width: 120px;
  border-radius: 4px;
}
.referral-progress-container .progress-bar {
  border-radius: 20px;
  height: 100%;
  background: radial-gradient(
    201.67% 201.67% at 50% -31.67%,
    #5d69cf 0,
    #342a6f 100%
  );
  box-shadow: 0 2px 12px 2px rgba(108, 93, 193, 0.24);
}
.referral-progress-container .progress-value p {
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.48px;
  margin: 0;
}
.referral-table-content table tbody tr td.date-value {
  color: #fff;
}
.referral-progress-container .progress-value p span {
  color: #757786;
}
.show-less {
  margin-top: 20px;
}
.wallet-referral-btn svg {
  transition: transform 0.3s ease-in-out;
}
.show-less svg {
  transform: rotate(180deg);
}
.referralGreen {
  color: #6cd9a5 !important;
  font-size: 14px;
  text-align: left;
}
.referaltheadMainWrap .transaction-body .transaction-body-column-new .profileBG,
.referralBg {
  display: flex;
  color: #fff !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 82px;
}
.referralBg {
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  border-radius: 6px;
  border: 2px solid #6cd9a5d6;
  background: radial-gradient(
    216.25% 216.25% at 50% -86.25%,
    #6cd9a5 0,
    #3aa16f 100%
  );
  height: 30px;
}
.referralProgress {
  background: #2b2b38;
  color: #666773;
  font-family: Jost;
  font-size: 14px;
  height: 40px;
  width: 85px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  border: 1px solid;
}
.refferal-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.disabled-page {
  color: #808e9b;
}
.pagination {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style-type: none;
}
.pagination li .previous-page {
  margin-right: 20px;
}
.pagination li .next-page {
  margin-left: 20px;
}
.pagination li a {
  color: #fff;
  font-size: 16px;
  border: 1px solid transparent;
  background: 0 0;
  height: 40px !important;
  width: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.pagination-active a {
  background: #202026;
  border-width: 1px;
  border-color: #6a71c7 !important;
  border-style: solid;
  box-shadow: 2px 2px 20px 0 #3c3c43;
}
.pagination li,
.referral-input button,
.referralBg {
  cursor: pointer;
}
li.next,
li.previous {
  padding: 0;
}
.referaalTableMainWrap,
li a.next-page,
li a.previous-page {
  display: flex;
}
/* .earnSvgIcon {
  width: 36px;
  height: 40px;
  display: flex;
  align-items: center;
} */
.earnSvgIcon svg {
  top: 56%;
  left: 52%;
  width: 36px;
  height: 40px;
  position: absolute;
  transform: translate(-50%, -50%);
}
/* .headerSvgIcon,
.headerSvgIcon svg,
.headerSvgIconCoin {
  width: 37px !important;
  height: 37px !important;
} */
.bonusCoinSvg,
.bonusCoinSvg svg {
  width: 43px;
  height: 43px;
}
.bonusCoinSvg img {
  position: absolute !important;
}
.bonusCoinSvg svg {
  left: 36%;
}
.referaltheadMainWrap .transaction-head {
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
.referaltheadMainWrap .transaction-head .fGap {
  display: flex;
  align-items: center;
  height: 50px;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;
}
.referaltheadMainWrap .transaction-head .sGap {
  display: flex;
  align-items: center;
  height: 50px;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 169.33px;
}
.referaltheadMainWrap .transaction-body .fGap .transaction-body-column,
.referaltheadMainWrap .transaction-head .fGap .transaction-head-column {
  margin-right: 20px;
  min-width: 36px;
}
.referaltheadMainWrap .transaction-head .transaction-head-column {
  padding: 13px 10px 13px 8px;
}
.referaltheadMainWrap .transaction-head .transaction-head-column-new {
  padding: 12px 10px 12px 8px;
}
.referaltheadMainWrap
  .transaction-body
  .transaction-body-column-new:first-child,
.referaltheadMainWrap
  .transaction-head
  .transaction-head-column-new:first-child {
  min-width: 147px;
}
.referaltheadMainWrap
  .transaction-body
  .transaction-body-column-new:nth-child(2),
.referaltheadMainWrap
  .transaction-head
  .transaction-head-column-new:nth-child(2) {
  min-width: 102px;
}
.referaltheadMainWrap
  .transaction-body
  .transaction-body-column-new:nth-child(3),
.referaltheadMainWrap
  .transaction-head
  .transaction-head-column-new:nth-child(3) {
  min-width: 243px;
}
.referaltheadMainWrap
  .transaction-body
  .transaction-body-column-new:nth-child(4),
.referaltheadMainWrap
  .transaction-head
  .transaction-head-column-new:nth-child(4) {
  min-width: 104px;
}
.referaltheadMainWrap .transaction-head span {
  font-size: 14px;
  font-weight: 500;
  color: #757786;
  line-height: 20px;
}
.referaltheadMainWrap .transaction-body .transaction-body-row {
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
.referaltheadMainWrap .transaction-body .transaction-body-row .fGap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;
}
.referaltheadMainWrap .transaction-body .transaction-body-row .sGap {
  display: flex;
  align-items: center;
  height: 50px;
  gap: 169.33px;
}
.referaltheadMainWrap .transaction-body span {
  font-size: 14px;
  font-weight: 500;
  color: #757786;
  line-height: 20px;
  display: flex;
}
.referaltheadMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column {
  padding: 10px 8px;
}
.referaltheadMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column-new {
  padding: 0 8px;
}
.referaltheadMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column-new
  .profileSpan
  span {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  line-height: 20px;
}
.referaltheadMainWrap .transaction-body:nth-child(2n) {
  background-color: #282831;
}
.referaltheadMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column-new
  img {
  height: 30px;
  width: 30px;
  display: flex;
}
.referaltheadMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column-new
  .clipPath {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 35px;
  background: #3e3e44;
  max-height: 35px;
  object-fit: cover;
  align-items: center;
  aspect-ratio: 1;
  border-color: #3e3e44;
  border-radius: 4px;
  justify-content: center;
}
.userProfileLevel {
  display: none !important;
}
.referaltheadMainWrap
  .transaction-body
  .transaction-body-column-new
  .profileBG {
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  border-radius: 6px;
  border: 1px solid var(--stroke-4, #ffffff0a);
  background: var(--Black-500, #2b2b38);
  height: 30px;
}
@media screen and (max-width: 991px) {
  .referral-table-content {
    border-radius: 0;
  }
  .referral-page {
    margin: 50px 0;
  }
  .referral-head {
    padding-top: 12px;
  }
  .referral-head-title h2 {
    font-size: 18px;
    line-height: 24px;
  }
  .pagination,
  .referral-head-title {
    gap: 8px;
  }
  .referral-head-title p {
    font-size: 14px;
    line-height: 16px;
  }
  .referral-head-list {
    padding-top: 24px;
  }
  .referral-view .wrapper-box:before {
    top: 50px;
  }
  .referral-head-list ul {
    justify-content: space-between;
  }
  .referral-head-list ul li img {
    order: 1;
    width: 28px;
  }
  .earnSvgIcon {
    height: 28px;
    width: 28px;
  }
  .referral-head-list ul li b.ref-earned {
    order: 1;
  }
  .referral-head-list ul li span {
    order: 3;
  }
  .referral-input-grid {
    padding: 12px 12px 15px;
    gap: 17px;
    flex-direction: column;
  }
  .referral-input-box {
    width: 100%;
    gap: 4px;
  }
  .referral-input-box label {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }
  .referral-input input {
    border-radius: 8px;
    height: 32px;
    font-size: 14px;
    line-height: 16px;
    padding: 8px 12px;
    font-weight: 400;
  }
  .referral-progress-container .progress-value p,
  .referralBg,
  .referralGreen,
  .userName p {
    font-weight: 500;
    font-size: 12px;
  }
  .referral-input button,
  .userName img {
    display: none;
  }
  .referral-input span {
    display: flex;
    position: absolute;
    right: 3px;
    width: 28px;
    height: 28px;
  }
  .referral-input span img {
    position: absolute;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
  }
  .referral-table-content table tbody tr td {
    font-size: 12px;
    line-height: 16px;
    padding: 8px 12px;
    font-weight: 500;
    white-space: unset;
  }
  .referral-table-content table thead tr th {
    font-size: 12px;
    line-height: 16px;
    padding: 12px;
  }
  .userName p {
    line-height: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 52px;
  }
  .referral-table-content table thead tr th:nth-child(3) {
    text-align: left;
  }
  .referralBg {
    height: 26px;
    width: 64px;
    border-radius: 6px;
    border: 2px solid #6cd9a5d6;
    background: radial-gradient(
      216.25% 216.25% at 50% -86.25%,
      #6cd9a5 0,
      #3aa16f 100%
    );
  }
  .referralProgress {
    font-size: 12px;
    height: 26px;
    width: 64px;
    border-radius: 6px;
  }
  .referral-progress-container {
    gap: 4px;
  }
  .referral-progress-container .progress {
    height: 2px;
    width: 80px;
  }
  .refferal-pagination {
    padding: 15px 13px;
  }
  .order_arrange {
    order: 1 !important;
  }
  .referral-head-list ul li {
    font-size: 14px;
    gap: 6px !important;
    line-height: 16px;
    border-radius: 6px;
  }
}
@media screen and (max-width: 767px) {
  .referral-table-content table tbody tr td {
    color: #fff;
  }
  .headerSvgIcon,
  .headerSvgIconCoin {
    width: 36px !important;
    height: 36px !important;
  }
  .headerSvgIconCoin {
    left: 6px !important;
  }
  .headerSvgIcon svg {
    width: 31.5px !important;
    height: 31.5px !important;
  }
  .pagination li a {
    border-radius: 8px;
    padding: 0;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pagination .previous {
    margin-right: 14px !important;
  }
  .pagination .next {
    margin-left: 14px !important;
  }
  .pagination .pagination-active {
    box-shadow: 0 4px 12px 2px #6c5dc13d;
  }
  .pagination,
  .referaltheadMainWrap .transaction-body .transaction-body-row .sGap {
    gap: 0;
  }
  .refferal-pagination {
    padding: 14px 12px 12px;
  }
  .referral-content {
    margin-top: 16px;
    border-radius: 16px;
  }
  .referaalTableMainWrap {
    overflow-x: auto;
    overflow-y: hidden;
  }
  .referral-view .wrapper-box:before {
    background-image: url("../../../public/images/history-page-bg.jpg") !important;
  }
  .referaltheadMainWrap .transaction-head {
    padding-left: 8px;
    padding-right: 8px;
    height: 40px;
  }
  .referaltheadMainWrap .transaction-head .sGap {
    gap: 0 !important;
  }
  .referaltheadMainWrap
    .transaction-body
    .transaction-body-column-new:first-child,
  .referaltheadMainWrap
    .transaction-head
    .transaction-head-column-new:first-child {
    min-width: 131px;
  }
  .referaltheadMainWrap
    .transaction-body
    .transaction-body-column-new:nth-child(2),
  .referaltheadMainWrap
    .transaction-head
    .transaction-head-column-new:nth-child(2) {
    min-width: 86px;
  }
  .referaltheadMainWrap
    .transaction-body
    .transaction-body-column-new:nth-child(3),
  .referaltheadMainWrap
    .transaction-head
    .transaction-head-column-new:nth-child(3) {
    min-width: 104px;
  }
  .referaltheadMainWrap
    .transaction-body
    .transaction-body-column-new:nth-child(4),
  .referaltheadMainWrap
    .transaction-head
    .transaction-head-column-new:nth-child(4) {
    min-width: 88px;
  }
  .referaltheadMainWrap .transaction-body .fGap .transaction-body-column,
  .referaltheadMainWrap .transaction-head .fGap .transaction-head-column {
    margin-right: 0;
    min-width: 30px;
  }
  .referaltheadMainWrap .transaction-body .transaction-body-row {
    padding-left: 8px;
    padding-right: 8px;
    height: 48px;
  }
  .referaltheadMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column-new
    .profileSpan
    span,
  .referaltheadMainWrap .transaction-body span,
  .referaltheadMainWrap .transaction-head span {
    font-size: 12px;
    line-height: 16px;
  }
  .referaltheadMainWrap .transaction-body span {
    flex-wrap: wrap;
  }
  .mobileMargin0 {
    margin-left: 0 !important;
    margin-top: 4px !important;
  }
  .referaltheadMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column-new
    .profileSpan
    span {
    margin-left: 8px;
  }
  .referaltheadMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column-new
    img {
    height: 32px;
    width: 32px;
  }
  .referaltheadMainWrap .transaction-head .transaction-head-column-new {
    padding: 12px 8px;
  }
  .referral-page {
    margin-bottom: 24px;
  }
  .referalBackBtnDiv {
    display: block;
    padding-top: 18px;
  }
  .earnSvgIcon svg {
    height: 28px;
    width: 28px;
    left: 54%;
  }
  .userProfileLevel {
    left: 50%;
    bottom: 0;
    height: 12px;
    padding: 0 2px;
    font-size: 10px !important;
    max-width: 24px;
    min-width: 24px;
    transform: translateX(-50%);
    font-weight: 500 !important;
    line-height: 12px !important;
    border-radius: 4px !important;
    color: #fff;
    border: 1px solid #383e4e;
    position: absolute;
    background: #383e4e;
    box-shadow: inset 0 4px 4px rgba(255, 255, 255, 0.04);
    font-style: normal;
    text-align: center;
    margin-left: 0 !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  .positionrelative {
    position: relative;
  }
  .referralBg {
    width: 70px !important;
    height: 28px !important;
    font-size: 12px !important;
  }
  .referaltheadMainWrap
    .transaction-body
    .transaction-body-column-new
    .profileBG {
    width: 70px !important;
    height: 28px !important;
    font-size: 12px !important;
    color: #757786 !important;
  }
  .pagination li a,
  .refferal-pagination .pagination .previous .previous-page svg {
    height: 28px !important;
    width: 28px !important;
  }
}
