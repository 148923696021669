.mainWrapRedeemTab h1 {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: #fff;
  margin-bottom: 0px;
  margin-top: 24px;
  max-width: 80%;
  margin: 0 auto;
}
.mainWrapRedeemTab {
  margin-top: 24px !important;
}
.mainWrapRedeemTab .inputMainWrap {
  margin-top: 24px;
  padding-bottom: 40px;
}
.RedeemLable span {
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  color: #757786;
}
.RedeemLable .copyWrapSec .coinsImg {
  background: transparent;
  outline: none;
  box-shadow: none;
}
.paymentMainContentWrap .copyWrap .coinsImg img {
  height: 34px;
  width: 40px;
  /* box-shadow: 
        0px 0px 30px 20px rgba(166, 92, 225, 0.2); */
}
.RedeemLable .copyWrap {
  margin-top: 4px !important;
}
.marginTop-8 {
  margin-top: 8px;
}
.RedeemLable .grayTxt {
  color: #4d4d5b !important;
}
.RedeemSpanMainTxt {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #757786;
  margin-bottom: 8px;
  display: block;
}
.achDirectRedeemMainWrap span {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 8px;
  display: block;
  color: #757786;
}

/* check box css */

#wrapper {
  display: flex;
}

#wrapper p {
  color: #fff;
  font-size: 18px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  padding: 15px 20px;
  margin: 0;
  margin-bottom: 18px;
}

.radio-button-container {
  color: #fff;
  display: block;
  position: relative;
  padding-left: 38px;
  line-height: 25px;
  margin-bottom: 24px;
  /* margin-left: 30px; */
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio-button-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 28px;
  width: 28px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 50%;
  border: 1.5px solid var(--Black-200, #757786);
  transition: all 0.3s;
  margin-bottom: 0px !important;
}

.radio-button-container:hover input ~ .checkmark {
  border-color: var(--Black-200, #757786);
}

.radio-button-container input:checked ~ .checkmark {
  background: radial-gradient(
    201.67% 201.67% at 50% -31.67%,
    #342a6f 0%,
    #5d69cf 99.99%
  );
  outline: 1.4px solid #ffffff0a;
  box-shadow: 0px 4px 12px 2px #6c5dc13d;
  border: none;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radio-button-container input:checked ~ .checkmark:after {
  display: block;
}

.radio-button-container .checkmark:after {
  top: 9px;
  left: 9px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #fff;
}
.secondLabel {
  margin-left: 46px;
}

/* checkbox css end */

.height32 {
  height: 32px;
}
.marginTop-24 {
  margin-top: 24px;
}

.input-container {
  display: flex;
  align-items: center;
  background-color: var(--Black-800, #1d1d23);
  padding: 9px 9px 9px 12px;
  border-radius: 8px;
  position: relative;
  margin-top: 4px;
}

.input-label {
  color: #888; /* Dimmed text color */
  font-size: 16px;
  flex: 1;
  margin-right: 10px;
}

.styled-input {
  background-color: var(--Black-800, #1d1d23);
  border: none;
  outline: none;
  color: #ffffff;
  font-size: 16px;
  flex: 2;
}

.styled-input::placeholder {
  color: #4d4d5b;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  font-family: Jost;
}

.icon-container {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-container img {
  width: 40px;
  height: 34px;
}
.firstHeadingWrap .archTxt {
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  color: #ffffff !important;
  margin-top: 0px !important;
  max-width: 100%;
  margin-bottom: 24px !important;
}
@media screen and (max-width: 767px) {
  .mainWrapRedeemTab .inputMainWrap {
    padding-bottom: 0px;
  }
  .input-container {
    padding: 0px 3px 0px 12px;
  }
  .achDirectRedeemMainWrap span {
    font-size: 14px;
    margin-bottom: 4px;
  }
  .RedeemLable span {
    font-size: 14px;
  }
  .firstHeadingWrap .archTxt {
    font-size: 20px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    color: #ffffff !important;
    margin-top: 41px !important;
    max-width: 80%;
    margin-bottom: 24px !important;
  }
}
