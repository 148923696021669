.kycVarificationMainWrap {
  background-color: #202027;
  box-shadow: 0px 2px 4px 0px #ffffff05 inset;
  padding: 16px;
  position: relative;
  min-width: 440px;
  height: 100vh;
}
.kycVarificationMainWrap::before {
  background-image: url(../../../../public/images/brownBGV.png);
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position-x: center;
}
.loginHeadeWrap .closeIcon {
  background: var(--Black-500, #2b2b38);
  border: 1.4px solid var(--stroke-4, #ffffff0a);
  box-shadow: 0px 2px 12px 0px #ffffff05 inset;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  position: relative;
  z-index: 9999;
  cursor: pointer;
}
.kycVarificationMainWrap .loginHeadeWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.kycVarificationMainWrap .loginLogoWrap {
  text-align: center;
  margin-top: 18px;
}
.kycVarificationMainWrap .loginLogoWrap .kycLogoImg {
  width: 68px;
  height: 76px;
  margin-bottom: 7px;
}
.secondCopyInput1 {
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: left;
}

.secondCopyInput1 .title {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #757786;
}

.secondCopyInput1 .custom-input {
  width: 100%;
  height: 32px;
  padding: 8px 12px 8px 12px !important;
  background-color: #1d1d23;
  border: 1px solid transparent;
  color: #b3b3b3;
  font-size: 16px;
  border-radius: 8px;
  outline: none;
}

.secondCopyInput1 .custom-input::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #4d4d5b;
  font-family: Jost !important;
}
.secondCopyInput2 {
  width: 50%;
  text-align: left;
}

.secondCopyInput2 .title {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #757786;
  margin-bottom: 4px;
}

.secondCopyInput2 .custom-input {
  width: 100%;
  height: 32px;
  padding: 8px 12px 8px 12px !important;
  background-color: #1d1d23;
  border: 1px solid transparent;
  color: #b3b3b3;
  font-size: 16px;
  border-radius: 8px;
  outline: none;
}

.secondCopyInput2 .custom-input::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #4d4d5b;
}

.secondCopyInput0 {
  display: flex;
  gap: 8px;
}

.secondCopyInput1 .first-input {
  margin-top: 8px;
}

.kycform {
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: 999;
  position: relative;
}
.kycFormWrap {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.stepWrap span {
  width: 100%;
  height: 16px;
  display: flex;
  justify-content: end;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: var(--Black-200, rgba(117, 119, 134, 1));
}

.css-13cymwt-control {
  background-color: #1d1d23;
  color: #757786;
  border: none;
  height: 32px;
  width: 140px;
  border-radius: 8px;
}

.kycHeaderWrap p {
  margin: 0px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: rgba(255, 255, 255, 1);
}
.react-select__control {
  background: var(--Black-800, rgba(29, 29, 35, 1));
  height: 32px;
  border: none !important;
  box-shadow: none !important;
  cursor: pointer;
  /* display: none; */
  border-radius: 8px !important;
  min-height: 32px;
  width: 100%;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12) inset;
}
.react-select__placeholder {
  color: var(--Black-300, rgba(77, 77, 91, 1)) !important;
}
.react-select__menu {
  background-color: var(--bg-head-menu) !important;
  margin-top: 5px !important;
}
.react-select__menu-list {
  color: #fff !important;
}
/* .react-select__option {
  background-color: var(--bg-head-menu) !important;
} */

.react-select__option:hover {
  background-color: #202020 !important;
}
.react-select__option:visited {
  background-color: #202020 !important;
}
.react-select__input-container {
  color: #fff !important;
}
.react-select__single-value {
  color: #fff !important;
}
.copyWrapSec .css-tj5bde-Svg {
  fill: #757786 !important;
}

@media screen and (max-width: 767px) {
  .kycVarificationMainWrap {
    height: 100vh;
    min-width: 0px;
  }
  .secondCopyInput1 .custom-input {
    padding: 4.1px 4px 4.1px 0px;
    line-height: 20px !important;
  }
}
